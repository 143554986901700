import React, { useContext } from 'react'
import { Link } from 'gatsby'
import { GeoContext } from '../state/geo'
import Container from '../components/container/container'
import TextContent from '../components/textContent/textContent'
import Layout from '../layout/layout'
import BackButton from '../components/backButton/backButton'

const ImprintPage = () => {
  const geo = useContext(GeoContext)
  return (
    <Layout>
      <Container>
        <BackButton />
        <TextContent>
          <h1>Imprint</h1>
          <p>
            <strong>Merck KGaA</strong>
            <br />
            <strong>Frankfurter Strasse 250</strong>
            <br />
            <strong>64293 Darmstadt</strong>
            <br />
            <strong>Germany</strong>
            <br />
            <br />
            <strong>Phone: +49 6151 72-0 </strong>
            <br />
            <strong>Telefax: +49 6151 72-2000</strong>
            <br />
            <br />
            <a
              href={`https://www.${geo?.url}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>www.{geo?.url}</strong>
            </a>
            <br />
            <a href={`mailto:service@${geo?.url}`}>
              <strong>service@{geo?.url}</strong>
            </a>
            <br />
          </p>
          <h2>Details</h2>
          <p>
            <strong>Legal form:</strong> Corporation with general partners
            <br />
            <strong>Commercial Register:</strong> AG Darmstadt HRB 6164
            <br />
            <strong>Registered Office:</strong> Darmstadt
            <br />
            <strong>Chairman of the Supervisory Board:</strong> Dr. Wolfgang
            Büchele
            <br />
            <strong>Executive Board:</strong>
            <br />
            Dr. Stefan Oschmann (Chairman & CEO)
            <br />
            Belén Garijo, MD (Stellvertretende Vorsitzende, stellvertretender
            CEO & CEO Healthcare)
            <br />
            Dr. Kai Beckmann (CEO Performance Materials)
            <br />
            Dr. Marcus Kuhnert (CFO)
            <br />
          </p>
          <p>
            <strong>
              Value Added Tax Identification Number (VAT ID): DE 811850788{' '}
            </strong>
          </p>
          <p>
            Competent Governmental Authority: Regierungspräsidium Darmstadt
            (Germany)
            <br /> This imprint is also valid for the Social Media platforms of
            the Merck Group e. g. for the site www.facebook.com/MerckGroup.
          </p>
          <p>
            <strong>
              Responsible for the content according to § 55 Abs. 2 RStV
              (German):
            </strong>
            <br />
            Markus Kaiser
            <br />
            Merck KGaA
            <br />
            Frankfurter Straße 250
            <br />
            64293 Darmstadt
            <br />
            Germany
          </p>
          <p>
            You may also have a look at our{' '}
            <Link to="/privacy">privacy statement</Link> to see that we take
            data protection and confidentiality very seriously and on our site
            do not make further use of your social media contributions.
          </p>
        </TextContent>
      </Container>
    </Layout>
  )
}

export default ImprintPage
